@media screen and (max-width: 1052px) {

  .auth-section1{
    display: none;
  }
  
  .auth-section2 {
    min-height: 1400px;
    /* justify-content: unset; */
  }
  .auth-section2-title{
    display: block;
  }

  .paiment-page{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .checkout-form {
    width: unset;
    margin: 1rem;
    max-width: unset;
  }
  .paiment-page > .bloc-2 {
    max-width: unset !important;
    text-align: left;
  }

    /* Paiment page */ 

  .products {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;
  }

  .product-card{
    width: 100%;
  }




  .bloc-2{
    margin: 1rem;
  }

  .content-bloc > div:not(:last-child) {
    border-bottom: 2px solid #756f6f57;
    border-right: none;
  }

  .column-bloc {
    box-shadow:  unset;
    height: 100%;
  }

  .content-bloc{
    align-items: flex-start;
    justify-content: flex-start;
  }

}

@media screen and (max-width: 768px) {



  .listing-section{
    padding-top: 1rem !important;
  }
  .listing-title{
    margin-bottom: 0.7rem !important;
  }

  .listing-tutorial {
    padding-left: 0rem !important;
    width: 100%;
    height: 100%;
  }

  .listing-elt{
    margin-left: 0rem !important;
  }

  .listing-number{
    width: 34px !important;
    height: 34px !important;
  }

  .listing-text {
    word-break: break-word;
    max-width: 280px;
  }


  .popup-container{
    overflow: scroll !important;
    height: 100% !important;
    margin-bottom: 3rem;
  }

  .popup-header {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
    width: 100%;
  }

  .popup-header > img{
    margin-top: 0px !important;
  }
  
  .form-create{
    align-items: center;
    max-height: 600px;
    height: 100%;
  }
  .formCreateElt{
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    row-gap: 0px !important;
    margin-left: 1rem;
    margin-bottom: 0px !important;
  }
  .formCreateElt > p {
    align-self: flex-start;
  }
  .formCreateElt > input {
    align-self: center;
    width: 300px;
    margin-right: 1rem;
    margin-bottom: 0px !important;
  }
  .auth-page {
    padding-top: 1rem;
    height: 100%;
  }
  .auth-input {
    row-gap: unset;
  }

  header {
    margin-top: 1rem;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    background-color: #fff;
  }

  .header-main-div{
    width: unset !important;
    margin-right: 2rem;
  }


  .logo-title {
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .logo-title > a > img {
    height: 70px !important;
  }
  footer {
    background-color: #fff !important;
  }

  footer > p {
    margin: 0 !important;
  }

  /* Auth form page */
  .authForm {
    max-width: 300px !important;
  }

  .authForm-footer {
    margin-top: 3rem;
    text-align: center;
  }

  /* Reinit password page */

  /* Add Cat */
  .bloc-cat-informations {
    flex-wrap: wrap !important;
  }

  .add-cat-page_section {
    padding: 1rem;
  }

  /* Add city modal */
  .swal2-container,
  .swal2-input {
    margin: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /* Settings Canal */
  .popup-content {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
    width: 100%;
  }


  .canal-options{
    flex-direction: column !important;
    row-gap: 1.5rem;
  }

  .buttons-stats-manage > button{
    margin-left: 0px;
    margin-top: 0px;
  }

.react-tooltip{
  width: 100% !important;
}
  .react-tooltip > div > h3{
    font-size: 16px;

  }

  .react-tooltip > div > p{
    font-size: 11px;

  }

  .react-tooltip > div > ul> li{
    font-size: 11px;

  }

  .react-tooltip > div > ul{
    margin-left: 0px;
    list-style-type: none;
    padding-left: 0;

  }

  .section-informations_canal, .section-informations_canal-members {
    width: 100%;
  }

  /* Cat page */ 
  .animals-parent-filters{
    padding-left:  unset !important;
    padding-right: unset !important;
  }



  .content-animaux{
    padding: unset !important;
  }

  .document-container > a {
    margin-left: 1rem;
    font-size: 10px;
  }

  .document-container{
    max-width: 50px;
  }

  .btn-kappze-id{
    padding: 0;
    align-self: center;
  }

  .btn-kappze-id > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .btn-kappze-id > div {
    align-self: flex-start;
  }

  .btn-kappze-id > div > div > button{
    padding: 0;
  }
  .document-container > .btn-supp{
    margin-bottom: 15px;
    padding: 5px;
  }

  /* Listing page */ 
  .header-listing{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }

  .header-listing{
    padding: 0 !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }


  /* Canal page */ 

  .popup{
    min-width: unset;
    width: 100%;
  }

  .inputs-filter-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    padding: 1rem;
    margin: 1rem;
    width: 100%;
}

  #content-informations{
    padding: unset;
  }
  #content-informations > div > p{
    word-break: break-all;
  }

  #form-create{
    padding: 1rem;
  }

  .canal-page_content-title-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
  }

  .page-title{
    width: 100%;
  }

  .label-animal-more{
    text-align: center;
  }

  #content-animaux{
    row-gap: 2rem;
  }

  #content-animaux, #content-secteurs, #content-informations{
    flex-wrap: wrap;
  }

  /* Listing animals */ 
  .list {
    padding: 0;
    padding-top: 1rem;
  }

  /* profile cat */ 

  .profile-cat-frist-row_column-1_name-age > div, .profile-cat-frist-row_column-1_name-age > .animal-age{
    align-items: center;
    text-align: center;
    justify-content: center;
  }


  .profile-cat-first-row_column-1_div-img{
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .profile-cat-frist-row_column-1_name-age > p {
    margin-bottom: 1rem;
    text-align: center;
  }

  .profile-cat-first-row_column-2 > div {
    justify-content: center;
  }

  .popup-content-image {
    row-gap: 0rem;
    margin-top: 1rem;
  }

  .popup-chosen-image{
    margin-top: 1rem;
  }

  .popup-image-label {
    margin-bottom: 2rem;
  }
  .tabs-content{
      width: unset !important;
    }
  .tabs{
    width: 100%;
    padding: 10px;
  }

  .tabs > label {
    padding: 1rem;
  }

  /* .content-bloc > div:not(:last-child) {
    border-bottom: 2px solid #756f6f57;
    border-right: none;
  } */

  .tabs > label.active > img.active{
    display: inline-block;
  }

  .tab-icon-label{
    display: inline-block;
    width: 25px !important;
    margin: 0 !important;
  }

  .tab-text-label{
    display: none !important;
  }

  .tabs > label{
    line-height: 70px;
  }

  .auth-input{
    flex-wrap: wrap;
  }

  .profile-animal-documents{
    width: 100%;
  }

  /* Setings Profil */
  .page-main-header{
    flex-wrap: wrap;
    row-gap: 1rem;
    padding: 1rem;
  }

  .page-main-header > h2{
    margin: 0 !important;
    padding: 0rem !important;
    text-align: center;
  }

  .editable-field{
    word-break: break-word;
  }



  /* Paiment page */ 

  .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
    justify-content: space-between;
  }

  .product-card{
    width: unset;
  }

  .checkout-form {
    width: unset;
    margin: 1rem;
    max-width: unset;
  }

  /* Facturation */ 
.facturation-elt{
  margin-top: 1rem;
  border-top: 2px solid #2f4f2f;
  padding-left: 0rem;
  padding-top: 1rem;
}

.facturation-elt-title{
  color: #2f4f2f;
  padding-left: 0rem;
}

.facturation-elt-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: unset;
  padding-left: unset;
}


/* Graph stats */ 
#graph-1, #graph-2, #graph-3, #graph-4, #graph-5, #graph-6{
  width: unset !important;
} 

.graph-scroll{
  display: block;
}

/* Sector Page */ 
.city-page_content-title, .btns-modify-supp-city{
  justify-content: center;
}

.btns-modify-supp-city{
  width: 100%;
}

.city-page_content > .section-button-general{
  margin-bottom: 3rem;
}

.select-trapped {
width: 100%;
margin-top: 1rem;
align-items: center;
}
}


