:root {
  --color-primary: darkslategray;
  --color-secondary: #0d1fa6;
  --color-tertiary: #e1e8ff;
  --color-text: #f4f9f8;
  --color-background: #ffffff;
  --color-divider: #cccccc;
}

html,
body,
#app,
#app > div {
  height: 100% !important;
  background-color: #122;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.btnLogout{
  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  transition: 0.3s;
}
.btnLogout:hover{
  background-color: #d15e41;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
}

.burger-line {
  height: 3px;
  background: #ddd;
  width: 100%;
}

.header-resp-nav {
  display: none;
  position: absolute;
  top: 12%;
  right: 0;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  height: auto;
}

.header-resp-nav.open {
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.header-resp-nav.open > span {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p,
a {
  font-family: "Wix Madefor Display", sans-serif;
  color: var(--color-text);
  text-decoration: none;
  transition: 0.3s;
}

.page-title {
  color: white;
}
a,
nav {
  cursor: pointer;
}

a:hover{
  color: #d15e41;
}

.button-general-link:hover{
  color: unset !important;
}


.button-general:disabled .button-general-link {
  pointer-events: none;
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.5; /* Assombrissement pour signaler l'indisponibilité */
}



.button-general:disabled .button-general-link {
  color: #ccc; /* Texte plus clair pour montrer que le bouton est désactivé */
}

.button-request-admin:disabled {
  cursor:not-allowed !important;
  opacity: 0.5;
}

.button-request-admin:hover{
  color: #fff !important;
}


nav > a {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 1rem;
  font-weight: bold !important;
}

h1,
h2,
h3 {
  font-family: "Wix Madefor Display", sans-serif;
  margin: 0;
}

textarea {
  padding: 1rem;
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
}

label {
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
}

button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: var(--color-primary);
  color: #ffffff;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 16px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button > * {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button:hover {
  background-color: #4a6769;
}

.google-login-btn {
  display: flex;
  align-items: center;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 3rem;
}

.google-login-btn:hover {
  background-color: #357abd;
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.export-button {
  border: 2px solid white;
}
.export-button:hover {
  color: darkslategrey;
  background-color: white !important;
}

.button-general-link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.button-general-link-premium{
  opacity: 0.6;
  cursor: not-allowed;
}

.button-general_signalements{
  color: #fff;
  background-color: #121212 !important;
}
.button-general_signalements > .button-general-link_signalements {
  color: #d15e41;
  font-weight: 600;
}

.button-add-canal {
  background-color: #122121;
  border: solid 2px #122121;
}

.button-add-canal:hover {
  border: solid 2px #4a6769;
}

.button-supp{
  background-color: #872929;
}

.button-supp:hover{
  background-color: #ad3535 !important;
}

.add-sector-form-swal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

#form-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
}

.formCreateElt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  min-width: 400px;
}

.formCreateElt > input {
  padding: 8px;
  min-width: 200px;
}

#canalForm {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  row-gap: 1rem !important;
}

.swal2-title {
  color: #122121;
}

.swal2-cancel,
.swal2-confirm,
.swal2-container,
.swal2-popup {
  border-radius: 8px !important;
}
.kappze-page {
  background-color: darkslategray;
  color: white;
  height: 100%;
  min-height: 1000px;
}

.animal-page{
  min-height: unset !important;
}

.unpublished-animal{
  background-color: #d15e41 !important;
}

.kappze-form-page {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 5%;
  row-gap: 2rem;
  max-width: 490px;
  border-right: 2px dashed white;
  border-bottom: 2px dashed white;
  background-color: rgba(47, 79, 79, 0.5);
}
/* authentication */

.auth-input {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


.auth-input-name{
  background-color: transparent;
  border-bottom: 2px solid #122 !important;
  height: 50px;
  color: #ddd;
  font-size: 20px;
}

.auth-input-name::placeholder{
  color: #ddd;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  transition: color ease-in 0.25s;
}

.auth-input-name:focus::placeholder{
  color: transparent;
  font-size: 20px;
}

.auth-input-name:focus{
  outline: none;
}


.auth-input > input {
  max-width: 1700px;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  padding: 1rem;
  box-sizing: border-box;
}

.error-message{
  color: rgb(211, 48, 48);
  margin-top: 5px;
  padding: 4px;
  border-radius: 2px;
  font-weight: 500 !important;
  text-align: center;
}

.authForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
  flex-wrap: wrap;
  max-width: 600px;
  width: 100%;
}

.authForm-footer {
  text-align: center;
}

.authFormCat {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
}

.auth-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  background: #122121;
  color: white;
  min-height: 1000px;
  height: 100%;
  flex-wrap: nowrap;
  width: 100%;
}

.auth-section1, .auth-section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 1150px;
  padding: 1rem;
  overflow-y: auto;
}

/* .auth-section2-title{
  display: none;
} */

.password-input {
  max-width: 400px;
}

.auth-section1 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 1150px;
}

.auth-section2 {
  min-height: 1090px;
}

.auth-section1 > h1 {
  font-size: 4em;
  margin-top: 4em;
  margin-bottom: 1em;
}

.auth-section1 > h2 {
  font-size: 2em;
}
.auth-section1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../public/cats/background-kappze-cat.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.auth-section1 * {
  position: relative;
  z-index: 1;
}


.auth-section1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #2A2E28;
  opacity: 0.92;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.background-overlay{
  background-color: #2A2E28;
  opacity: 0.60;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}



.auth-link-registration {
  color: white;
  background: none;
}

.auth-link-registration > a {
  color: white;
  background: none;
  margin: 0px;
  text-decoration: none;
  font-weight: bold;
}

.login-button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
  min-width: 190px;
  font-size: 1rem;
  flex-wrap: wrap;
  border-radius: 2px;
}

.login-button > img,
.register-button > img {
  max-height: 26px;
}

.register-button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-width: 125px;
  font-size: 1rem;
  flex-wrap: wrap;
}

.button-update {
  display: inline-block;
  opacity: 1;
  padding: 0px !important;
  margin: 5px;
  margin-left: 1rem;
  background-color: #ddd !important;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 5px;
  border: 2px solid #122121 !important;
  border-radius: 50px;
  cursor: pointer;
}

.button-update{
  transition: opacity 0.2s ease, transform 0.2s ease, background 0.3s ease, width 0.3s ease;
  opacity: 0;
  /* width: 0; */
  transform: scale(0);
  height: max-content;
  
}


.button-update.visible {
  width: auto;
  opacity: 1;
  transform: scale(1);
  
}

.button-update.hidden {
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}


.status-elt {
  transition: opacity 0.2s ease, transform 0.2s ease, background 0.3s ease;
  opacity: 0;
  transform: scale(0);
  height: max-content;
}

.status-elt.visible {
  opacity: 1;
  transform: scale(1);
}

.status-elt.hidden {
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}

.select-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  margin: 0;
}

.select-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  height: 0; 
  overflow: hidden;
  margin: 0;
}



.mother-select-p-hidden{
  margin: 0;
}


.mother-select-p-visible{
  margin: 16px 0px;
}

/* Home page */
.home-page > h2 {
  color: white;
}

.header-listing > h2 {
  padding-left: 1rem;
}

/* listing cat*/

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.searching-member{
  padding: 0.4rem !important;
  max-width: 250px !important;
  border: solid 1px white !important;
  border-radius: 5px;
}

.reset-button{
  border-radius: 20px;
  display: flex;
  column-gap: 1rem;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 0px;
  padding-left: 10px;
}
.reset-text{
  margin: 3px 0px;
}

.reset-icon {
  display: flex;
  align-items: center;
}

.undercross{
  width: 24px;
  height: 24px;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
}
.cross {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  border-radius: 50%;
  width: 100%;
  color: #2f4f2f
}

/* Pagination */

.list .pagination {
  display: initial;
  grid-template-columns: initial;
  gap: initial;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 40px;
}

.paginationButton {
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 2px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  color: red;
}

.paginationButton:hover {
  background-color: #ccc;
}

.paginationText {
  font-size: 16px;
  font-weight: bold;
  margin: 0 5px;
  color: "#ddd" !important;
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.card,
.card-canal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8ff;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.3s ease 0s;
}

.elipsis-card{
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 280px;
}


.card-canalAssociation {
  background-color: #4e7070;
}

.card > img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
  max-height: 200px;
}

.card h2,
.card-canal h2 {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #122121;
}

.card p,
.card-canal p {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 14px;
  color: #122121;
  font-weight: 500;
}

.card-canalAssociation p, .card-canalAssociation h2 {
  color: #d3d3d3 !important;
}

.card-canal {
  max-width: 350px;
}

.img-informations {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  margin: 1rem;
}

.card-canal_img-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.card-canal > .card-canal_img-title > h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-bottom: 1.2rem;
  color: #122121;
}

.card-canal > .card-canal_img-title > span > img {
  max-width: 115px;
  max-height: auto;
  border-radius: 2px;
}

.borders-container {
  display: flex;
  height: 100%;
  width: 100%;
  column-gap: 2px;
}

.border {
  width: 7%;
  height: 5px;
  border-radius: 2px;
}

.border1 {
  background-color: #122121;
}
.border2 {
  background-color: #122121;
}
.border3 {
  background-color: #2f4f4f;
}

.img-informations-general {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
}

.card .description {
  color: #757575;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-page_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
}

.fade-in.home-page_content-title {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
  font-size: 4rem;
  color: white;
}

.fade-in.home-page_content-buttons {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.home-page_content-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.user-page > .list {
  margin: 1rem;
}

.user-page > .list > .card {
  max-width: 300px;
  max-height: 150px;
  height: 100%;
  border-radius: 4px;
  align-items: flex-start;
  justify-content: flex-end;
}

.user-page-editable-fields > .editable-field > p,
.user-page-editable-fields > .editable-field > h2 {
  color: #122121 !important;
}

.user-page-editable-fields > p,
.user-page-editable-fields > h3 {
  color: #122121;
}

.list-canal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  row-gap: 3rem;
  padding: 3rem;
}

.how-does-it-works{
  height: 100%;
  margin-bottom: 1px solid black !important;
  color: #ddd !important;
  background-color: #d15e41;
  transition: color 0.3s ease;
  transition: background 0.3s ease;
  font-weight: bolder;
  margin-top: 1rem;
}

.how-does-it-works:hover{
  color: #d15e41 !important;
  background-color: #fff;
}

@media (max-width: 767px) {
  .list-canal {
    grid-template-columns: 1fr;
  }
}

.card-canal {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start !important;
  transition: all 0.3s ease 0s;
}

.filtered-page {
  height: unset;
}

.labelFilters {
  font-weight: 500;
  font-family: "Wix Madefor Display", sans-serif !important;
  color: #2f2f2f;
}
.canal-page_content,
.city-page_content,
.sector-page_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-input-create {
  display: flex;
  row-gap: 1rem;
  column-gap: 2rem;
  width: 100% !important;
  margin-bottom: 20px;
}

.form-input-create > label {
  flex-basis: 33%;
  text-align: left;
}
.form-input-create > input {
  flex-basis: 66%;
}

.canal-page_main,
.page-main {
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: darkslategray;
}

.filtered-page
  > .section-animalFilters
  > .animals-parent-filters
  > .filter-buttons-container
  > .buttonsFilter {
  background-color: transparent !important;
  border: none !important;
}

.filtered-page
  > .section-animalFilters
  > .animals-parent-filters
  > .filter-buttons-container
  > .buttonsFilter:hover {
  background-color: #4a6769 !important;
  transition: background 0.4s !important;
  border: none !important;
}

.profile-cat
  > .profile-cat_section
  > .animals-parent-filters
  > .filter-buttons-container
  > .buttonsFilter {
  background-color: #122121 !important;
  transition: background 0.4s !important;
  border: none !important;
}

.profile-cat
  > .profile-cat_section
  > .animals-parent-filters
  > .filter-buttons-container
  > .buttonsFilter:hover {
  background-color: #4a6769 !important;
  border: none !important;
}


.text-hover-style > foreignObject > div {
  transition: all 0.2s ease;
}

.text-hover-style:hover > foreignObject > div{
  color: #d15e41 !important;
}



.canal-page_content-title > h1 {
  color: white;
}

.canal-page_content-title-img {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.canal-page_content-title-img > span > img {
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  width: auto;
  max-width: 100px;
}
.canal-page_content-title-img > .lazy-load-image-background {
  height: 100px !important;
  overflow: hidden !important;
  width: 100px !important;
}

.section-informations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.section-informations > .tabs > label:hover{
  color:#d15e41;
  transition: all 0.2s;
}

.section-informations_button-general {
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  height: 30%;
  row-gap: 1rem;
}

.section-informations_button-general > .button-general {
  margin: 0;
  background-color: #122121;
}

.btn-supp {
  background-color: darkred;
}

.btn-masked {
  display: none;
}

.btn-validate,
.swal2-confirm {
  color: #f4f9f8 !important;
  border: solid 2px #2f2f2f !important;
  text-transform: uppercase !important;
  font-size: 0.9rem !important;
  transition: 0.2s;
}

.btn-validate:hover,
.btn-cancel:hover,
.swal2-confirm:hover,
.swal2-cancel:hover {
  background-color: #122121 !important;
  color: #f4f9f8 !important;
  border: solid 2px #2f4f4f !important;
}

.btn-cancel,
.swal2-cancel {
  background-color: #f4f9f8 !important;
  color: #607d8b !important;
  border: solid 2px #607d8b !important;
  text-transform: uppercase !important;
  font-size: 0.9rem !important;
  transition: 0.2s;
}

.btns-simple-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-left: 1rem;
}

.btn-simple-edit {
  background-color: #ffff !important;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-simple-edit img {
  width: 20px;
}

.btn-supp:hover {
  background-color: rgb(135, 41, 41);
}

.button-general {
  background-color: #122121;
}

.btn-modify:hover,
.button-general:hover {
  background-color: #4a6769;
}

.btns-modify-supp {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.section-informations_canal,
.section-informations_cities,
.section-informations_secteur,
.bloc-information {
  background-color: #f8f8ff;
  padding: 1rem;
  min-width: 200px;
  border-radius: 2px;
}

.bloc-information {
  width: 100%;
}

.bloc-information > .role-modification > button, .bloc-information > .btn-supp {
  margin-left: 0;
}

.role-modification{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bloc-information > p,
.bloc-information > p,
.bloc-information > p > a {
  color: #122121;
}

.bloc-information > h2,
.bloc-information > h3 {
  color: darkslategray;
  font-size: 1rem;
  font-weight: 500;
}

.span-div-label-bloc-informations {
  font-weight: 600;
  color: #122;
  font-family: "Wix Madefor Display", sans-serif !important;
}

.span-div-label-bloc-informations > .editable-field > .editable-fields-value {
  color: #122121 !important;
}

.bloc-information > .editable-field > .editable-fields-value, .bloc-information > div > .section-informations_canal-general > .editable-field > .editable-fields-value {
  color: #122121;
}

.canal-users > .bloc-information {
  width: unset;
}

.span-div-bloc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 1rem;
  row-gap: 11px;
  margin-bottom: 11px;
}

.span-div-bloc-colors {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: unset;
}

.label-colors {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.animals-parent-filters {
  /* padding-right: 8%; */
  padding-top: 2%;
}

.section-animalFilters {
  padding-left: 8%;
  padding-right: 8%;
}

.section-animalFilters_signalements {
  padding-left: 0%;
  padding-right: 0%;
}

.animals-parent-filters > div {
  padding-left: 0;
}
.animals-filters {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 40px;
  margin-bottom: 20px;
}

.inputs-filter-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  padding: 1rem;
  margin: 1rem;
}

.input-filter-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}

.inputFilter {
  align-self: flex-start;
  padding: 0.2rem;
}

.no-animals {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10%;

  background-color: #f8f8ff;
}
.no-animals > * {
  color: #122121;
}

.editable-field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.user-page-editable-fields {
  padding: 1rem;
  height: 100%;
}

.user-page-bloc-settings {
  background-color: #f4f9f8;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 5px;
}

.user-page-bloc-settings-title {
  margin-top: 2rem;
  color: #f4f9f8 !important;
}

.user-page-bloc-settings > div > .editable-field > .editable-fields-value {
  color: #122121 !important;
  margin: 0;
}

/* Cards  */

/* .cards {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 130px));
  padding: 2rem;
  padding-left: 0rem;
}

.cards-container-chatperdu{
  grid-template-columns: repeat(12, minmax(auto, 130px)) !important;
  padding-left: 2rem;
}

.card-animal {
  grid-column-end: span 3;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f4f9f8;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  position: relative;
}

.card-animal-chatperdu{
  background-color: #755391;
}

.card-animal__title-chatperdu, .card{
  color: #ddd;
}
.card-first_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.card-first_row > img {
  width: 10% !important;
}

.card-second_row {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0px 20px;
}

.card-third_row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px 20px;
}
.card-animal:hover, .card-canal:hover {
  transform: translateY(-7px);
}

.card-animal__image-container {
  width: 100%;
  height: auto;
}
.card-animal__image-container span {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
  background-color: #26252d;
}

.card-animal__image-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-animal__content {
  padding-bottom: 20px;
  width: 100%;
}

.card-animal__title {
  margin-bottom: 20px;
  color: #122121;
  font-size: 1rem !important;
}

.card-animal__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px;
  color: #122121;
  font-family: "Wix Madefor Display", sans-serif !important;
  padding-bottom: 1rem;
}

.card-animal__info-text{
  color: #ddd !important;
  text-transform:inherit;
}

.card-animal__info-text-chatperdu{
  color: #ddd;
  text-transform:inherit;
  text-align: left;
  align-self: flex-start;
} */


/* Cards  */


.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cartes par ligne */
  gap: 1rem; /* Espace entre les cartes */
  /* padding: 2rem; */
  justify-content: center; /* Centre les cartes horizontalement */
}
.cards-container-chatperdu {
  grid-template-columns: repeat(12, minmax(auto, 130px)) !important;
  padding-left: 2rem;
}

.card-animal {
  /* grid-column-end: span 3; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f4f9f8;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  position: relative;
}

.card-animal-chatperdu {
  background-color: #755391;
}

.card-animal__title-chatperdu, .card {
  color: #ddd;
}

.card-first_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.card-first_row > img {
  width: 10% !important;
}

.card-second_row {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0px 20px;
}

.card-third_row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px 20px;
}

.card-animal:hover, .card-canal:hover {
  transform: translateY(-7px);
}

.card-animal__image-container {
  width: 100%;
  height: 200px; /* Fixe la hauteur des images */
  overflow: hidden;
  position: relative;
  background-color: #26252d;
}

.card-animal__image-container span {
  width: 100%;
  height: 100%; /* Assure que le span prend toute la hauteur du conteneur */
  display: block; /* Assure que le span est un bloc */
  position: relative;
}

.card-animal__image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-animal__content {
  padding-bottom: 20px;
  width: 100%;
}

.card-animal__title {
  margin-bottom: 20px;
  color: #122121;
  font-size: 1rem !important;
}

.card-animal__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px;
  color: #122121;
  font-family: "Wix Madefor Display", sans-serif !important;
  padding-bottom: 1rem;
}

.card-animal__info-text {
  color: #ddd !important;
  text-transform: inherit;
}

.card-animal__info-text-chatperdu {
  color: #ddd;
  text-transform: inherit;
  text-align: left;
  align-self: flex-start;
}


.button-icon-cards {
  padding: 3px;
  margin-right: 5px;
  width: 20px !important;
  height: 20px;
}

.button-icon-cards-negative {
  background-color: #872929 !important;
}

.button-icon-cards-unknown {
  background-color: #f78503 !important;
}


.button-icon-cards-additionnal {
  background-color: #2f4f4f;
  border-radius: 40px !important;
}

@media only screen and (max-width: 1000px) {
  .card-animal {
    grid-column-end: span 6;
  }
}

@media only screen and (max-width: 700px) {
  main {
    grid-gap: 20px;
  }
  .card-animal {
    grid-column-end: span 12;
  }

  .button-general {
    width: 100%;
  }

  .button-general-ia {
    width: unset;
  }
}

@media only screen and (max-width: 500px) {
  main {
    grid-template-columns: 10px repeat(6, 1fr) 10px;
    grid-gap: 10px;
  }
  .cards {
    grid-column: 1 / span 2;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 20px;
  }

  .cards-container-chatperdu{
    padding-left: 0rem !important;
  }

  .card-animal {
    grid-column-end: span 6;
  }
}

/* Join canal page */

.join-asso-page_section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 0.9rem;
}

.join-asso-page_section > input {
  max-width: 370px;
  width: 1000px;
  height: 45px;
  border-radius: 5px;
  font-size: 1.5rem;
}

/* Add canal page */

.add-asso-page_section > div > label > input {
  max-width: 370px;
  width: 1000px;
  height: 45px;
  border-radius: 5px;
  font-size: 1.5rem;
}

.add-asso-page_section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
}

.add-asso-page_section > button {
  max-width: 100px;
  margin: 0;
}

/* Create cat profile page */


.image-upload-container {
  width: 100px;
  height: 100px;
  position: relative;
  cursor: pointer;
  box-shadow: 4px 15px -3px #000000;
}

/* .avatar-preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; 
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #122;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.avatar-preview, .avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Ceci rend l'image/le placeholder ronde */
  object-fit: cover; /* Assure que l'image couvre tout le cercle */
  box-shadow: 4px 15px -3px #000000; /* Applique une ombre à l'élément circulaire */
}

.avatar-placeholder {
  background-color: #122; /* Couleur de fond pour l'espace vide */
  display: flex;
  justify-content: center;
  align-items: center;
}


.add-cat-page_section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 3rem;
}

.add-cat-page_section > .authForm {
  align-items: flex-start;
}

.select-localization {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropzone {
  max-height: 120px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 2px;
}

.dropzone > p {
  color: grey;
}

.image-analyzer-container{
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  align-items: flex-start;
  padding: 0 !important;
}

.image-analyzer-container > div {
  box-shadow : 3px 4px 15px -3px #000000;
}

.status-container{
  align-self: center;
  text-align: center;
  box-shadow : unset !important;
}

.status-container > p {
  margin-top: 0 !important;
}

progress {
  width: 100%;
  height: 20px;
  background-color: #122121;
  border-radius: 8px;
  transition: width 0.4s ease !important;
}

progress[value]::-webkit-progress-bar {
  background-color: #cae4d9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.25) inset;
  transition: width 0.4s ease !important;
}

progress[value]::-webkit-progress-value {
  background-color: #122121;
  border-radius: 8px;
  transition: width 0.4s ease !important;
}

progress[value]::-moz-progress-bar {
  background-color: #cae4d9;
  border-radius: 8px;
  transition: width 0.4s ease !important;
}

.grayscale-svg {
  filter: grayscale(100%) brightness(1.4);
  max-width: 150px;
  width: 100%;
}


.bloc-cat-informations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
  background: white;
  color: #122121;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  box-shadow: 3px 4px 15px -3px #000000;
}

.adding-cat-informations {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 1rem;
  row-gap: 1rem;
}

.bloc-cat-informations > h3 {
  color: darkslategray;
  font-weight: 500;
}

.create-animal-add-component {
  margin-top: 1rem;
  border: 2px dashed white;
}

.authForm > div > input {
  margin-top: 2px;
}

.profile-cat_section > *,
.profile-cat_section > .editable-fields,
.profile-cat_section > .editable-fields > .editable-fields-value {
  color: #f4f9f8 !important;
}

.pastille_role, .pastille_situation, .pastille_lost {
  width: 100px;
  height: 17px;
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin-top: 1.2rem;
}

.pastille_situation-card {
  border-radius: 0;
}


.pastille_situation{
  position: absolute;
  z-index: 2;
  right: 0;
}



.pastille_lost{
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
}

.pastille_situation-profile{
  position: unset;
  margin-top: unset;
}
.pastille_situation-belonged{
  margin-top: 0rem;
  background-color: #4f3565;
}

.pastille_situation-libre{
  margin-top: 0rem;
  background-color: #122;
}

.pastille_situation-errant{
  margin-top: 0rem;
  background-color: #eb6a4a;
}

.pastille_situation-lost{
  margin-top: 0rem;
  background-color: #962104;
}

.pastille_situation-sterilized{
  margin-top: 0rem;
  background-color: rgb(2, 156, 110);
}

.pastille_situation-trapped{
  margin-top: 0rem;
  background-color: hsl(39, 88%, 53%);
}

.pastille_situation-trapped-card{
  margin-top: 0rem;
  background-color: hsl(39, 88%, 53%);
  border-radius: 0;
  left: 0 !important;
  bottom: 0;
}

.pastille_situation-trapped > span {
  color: #000;
}

.pastille_situation-sterilized-not{
  margin-top: 0rem;
  background-color: #731e33;
  width: 100%;
  max-width: 260px;
}

.pastille_role-admin {
  background-color: darkred;
  border-radius: 50px;
  padding: 3px;
}

.pastille_role-association {
  background-color: #eb6a4a;
  border-radius: 50px;
  padding: 3px;
}

.pastille_role-contributor {
  background-color: #1e58a3;
  border-radius: 50px;
  padding: 3px;
}

.pastille_role-editor {
  background-color: darkslategray;
  border-radius: 50px;
  padding: 3px;
}

.pastille_role-admin-text,
.pastille_role-editor-text {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Wix Madefor Display", sans-serif;

}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 500px;
  min-height: 250px;
}

.popup-singleinput > input{
  padding: 0.3rem;
}

.popup-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 0.9rem;
}

.popup-buttons button {
  margin-left: 10px;
}

.button-update {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-icon {
  width: 30px;
  margin: 5px;
}

.button-update:hover{
  background-color: #69b3a2 !important;
}

/* Profile Cat */

.profile-cat_section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
}

.information-unpublished {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background-color: #fff;
  width: 100%;
}

.information-unpublished-text > p {
  color: #000;
}

.profile-cat-first-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.profile-cat-first-row_column-1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 1rem;
}


/* Le conteneur carré */
.profile-cat-first-row_column-1_img {
  background: transparent;
  padding: 3px;
  border-radius: 3px;
  width: 189px;
  height: 189px;
  position: relative;
  overflow: hidden;
}

.profile-cat-first-row_column-1_img > span > img {
  border-radius: 50%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid #69b3a2;
  transition: border-color 0.5s ease !important;
}


.profile-cat-first-row_column-1_img > span > img:hover {
  border-color: #69b3a2;
  transition: border-color 0.5s ease;
}

.profile-cat-first-row_column-1_img {
  width: 140px;
  height: 140px;
  display: inline-block;
  overflow: hidden;
}

.profile-cat-first-row_column-1_img > span {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.profile-cat-first-row_column-1_img > span > img {
  width: 100%;
  height: auto;
}

.profile-cat-first-row_column-1_div-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-cat-frist-row_column-1_name-age
  > .editable-field
  > .editable-fields-label {
  display: none;
  color: white;
}

.animalName {
  font-size: 25px !important;
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
  margin: 0 !important;
  text-transform: uppercase;
}

.profile-cat-frist-row_column-1_name-age
  > .editable-field
  > .editable-fields-value {
  margin: 0;
}

.animal.age {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.profile-cat-frist-row_column-1_name-age > * {
  margin: 0;
}

.animal-age {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.span-div-bloc > .editable-field > .editable-fields-value {
  color: #122121;
}

.section-informations_canal > .editable-field > .editable-fields-value, .section-informations_canal > div > .section-informations_canal-general > .editable-field > .editable-fields-value{
  margin: 0;
}

.profile-cat-first-row_column-1_img {
  position: relative;
}

.profile-cat-first-row_column-1_img > span > img {
  object-fit: cover;
  height: 100%;
}

.button-update-img {
  left: 144px;
  width: auto !important;
}

/* Canal Settings */
.img-canal > img {
  max-width: 189px;
  height: 100%;
  object-fit: contain;
}

.img-canal > .button-update-img {
  left: 290px;
}

.section-informations_canal-members {
  height: 100%;
}

.canal-users {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
}

.popup-content > select {
  font-size: 1.3rem;
  padding: 0.4rem;
}
.popup-content_resp{
  display: flex;
  flex-direction: column;
  row-gap: 0rem !important;
  margin-top: 1rem;
}
.popup-content > h2 {
  color: darkslategray;
}

select {
  border-radius: 2px;
  padding: 0.4rem;
  border: 2px solid darkslategray;
}

input[type="text"] , input[type="password"] {
  border: 2px solid darkslategray;
  font-family: "Wix Madefor Display", sans-serif;
}

input[type="email"] {
  border: 2px solid darkslategray;
  font-family: "Wix Madefor Display", sans-serif;
}

.return-div > button:hover {
  background-color: white;
  color: darkslategray;
}


/* Styles pour les écrans de 481px à 767px (smartphone paysage) */
@media screen and (min-width: 481px) and (max-width: 767px) {
  /* Vos règles CSS ici */
  header {
    justify-content: center;
    flex-direction: column;
  }

  .header-resp {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  header {
    flex-direction: column;
    row-gap: 2rem;
    padding: unset !important;
  }

  .authForm {
    max-width: 200px !important;
  }
  .home-page_content-title {
    font-size: 3rem !important;
    margin-left: 1rem;
  }

  .home-page_content {
    margin-top: -30rem;
  }

  .profile-cat {
    padding: 1rem !important;
  }
  .profile-cat-first-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .profile-cat-first-row_column-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .section-informations_canal {
    width: 100%;
  }
  .profile-cat-frist-row_column-1_name-age {
    margin-top: 1rem;
  }

  .list-canal {
    padding: 1rem !important;
  }

  .page-main {
    padding: 1rem !important;
  }

  /* Loading page */
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh !important;
  }

  .loading-wrapper-column-title > h2 {
    text-align: center;
    font-size: 2rem !important;
    margin-bottom: 4rem;
  }

  .loading-wrapper-column-paws {
    display: flex;
  }

  .paw {
    background-size: cover;
    width: 60px !important;
    height: 40px !important;
    margin: 0 10px !important;
    animation: fade 2s ease-in-out infinite;
  }

  .paw:nth-child(2) {
    animation-delay: 0.3s;
  }

  .paw:nth-child(3) {
    animation-delay: 0.6s;
  }

  .section-informations {
    width: 100%;
  }
}

/* Loading page */
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #2f4f4f;
}

.loading-wrapper-column-title > h2 {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: white;
}

.loading-wrapper-column-paws {
  display: flex;
}

.paw {
  background-size: cover;
  width: 115px;
  height: 100px;
  margin: 0 20px;
  animation: fade 2s ease-in-out infinite;
}

.paw:nth-child(2) {
  animation-delay: 0.3s;
}

.paw:nth-child(3) {
  animation-delay: 0.6s;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.loading-logo {
  width: 100px;
  height: 100px;
  background-image: url("./assets/kappze_logo_circle_noir_roigne.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotate 1.1s infinite, pulse 1.1s infinite; /* Modification de l'animation fadeInOut à pulse */
  animation-timing-function: cubic-bezier(1, 0, 0.5, 1);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation d'opacité pour l'effet de pulsation */
@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  } /* Mi-transparence au début et à la fin */
  50% {
    opacity: 1;
  } /* Complètement visible au milieu */
}

/* TRANSITION */

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* Documents */

.document-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.document-container > a {
  margin-left: 1rem;
}

.document-container > .btn-supp {
  font-size: 20px !important;
  color: grey !important;
  background-color: transparent !important;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.link {
  fill: none;
  stroke: #f4f9f8;
  stroke-opacity: 0.4;
  stroke-width: 2px;
}

.ReactModal__Overlay {
  background-color: rgb(25 20 20 / 75%) !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.notificationPremium{
  opacity: 0.54;
  
}

.notificationPremium > * {
  cursor: not-allowed;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 3px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  bottom: -1px;
  background-color: #122121;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 1px 1px 3px 0 rgba(44, 12, 12, 0.2);
}

input:checked + .register-slider {
  background-color: #2f4f4f;
}


.register-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  bottom: -1px;
  background-color: #d15e41;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 1px 1px 3px 0 rgba(44, 12, 12, 0.2);
}

input:checked + .slider {
  background-color: #d15e41;
}

input:checked + .register-slider {
  background-color: #2f4f4f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #122121;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.color-checkbox-container {
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.color-checkbox-container input[type="checkbox"] {
  display: none;
}

.color-checkbox-container::after {
  content: "";
  position: absolute;
  display: none;
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.color-checkbox-container input[type="checkbox"]:checked ~ ::after {
  display: block;
}

.color-checkbox-container input[type="checkbox"]:checked {
  background-color: #d15e41;
}

input[type="date"],
input[type="file"] {
  font-family: "Wix Madefor Display", sans-serif !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.animals-filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
}

.buttonsFilter {
  border-radius: 2px !important;
  font-family: "Wix Madefor Display", sans-serif !important;
  margin: 0;
  border: 1px solid #fff;
}
.buttonResetFilter {
  background-color: #122121 !important;
}

.buttonCloseContainer {
  position: relative;
  width: 100%;
}

.buttonCloseFilter {
  position: absolute;
  top: 0;
  right: 0;
  right: 10px;
  background-color: transparent !important;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bolder;
  font-family: "Wix Madefor Display", sans-serif;
  transition: all 0.2s ease;
}

.buttonCloseFilter:hover{
  background-color: #121212;
  color:#d15e41;
}

/* Checkout form */
.checkout-form {
  margin: 2rem;
  margin-left: 10rem;
  margin-right: 0rem;
  margin-top: 0rem;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f4f9f8;
}

.checkout-button {
  background-color: rgb(17, 34, 34) !important;
  transition: background 0.4s;
}
.checkout-button:hover {
  background-color: #2f4f4f !important;
  transition: background 0.4s;
}

.bloc-2 > p {
  margin-top: 0;
}

.bloc-1 {
  flex-shrink: 1;
}
.bloc-2 {
  margin: 1rem;
  flex-shrink: 1;
}

.checkout-form p {
  color: rgb(135, 41, 41);
  font-weight: bold;
}

.checkout-form input,
.checkout-form button {
  width: 100%;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  font-size: 1rem;
  transition: border-color 0.3s;
  box-sizing: border-box;
  font-family: "Wix Madefor Display", sans-serif !important;
}

.checkout-form input:focus {
  border-color: #2f4f4f;
  outline: none;
}

.checkout-form button {
  background-color: #d15e41;
  color: #f4f9f8;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Wix Madefor Display", sans-serif !important;
  font-weight: bold;
}

.checkout-form button:hover {
  background-color: #d15e41c5;
}

.checkout-form label {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  font-size: 1rem;
  cursor: pointer;
}

.checkout-form label input[type="checkbox"] {
  margin-right: 0.5rem;
}

.checkout-form label a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.checkout-form label a:hover {
  color: #0056b3;
}

.StripeElement {
  margin-top: 2rem;
  margin-bottom: 2rem;
}




.loaderPayment{
  /* padding-top: 10px;
  padding-bottom: 10px; */
  border-top: 4px solid #2f4f4f;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin 1s linear infinite;
  z-index: 9999;
  align-self: center;
  /* margin-left: 20px; */
}

.loaderPaymentAddCat{
  
  border-top: 4px solid #9dd1d1;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin 1s linear infinite;
  z-index: 9999;
  align-self: center;
  /* margin-left: 20px; */
}

/* Custom animation */

@keyframes customSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes customSlideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes customFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes customFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.popup-logo > img {
  max-width: 40px;
}

.editable-fields-label,
.swal2-title {
  color: #122121 !important;
  font-size: 20px !important;
  margin: 10px;
}

.popup > textarea {
  margin-left: 40px;
  margin-right: 40px;
}
.popup-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.popup-entering,
.popup-exiting {
  animation-duration: 0.5s;
}

.popup-entering {
  animation-name: customFadeIn;
}

.popup-exiting {
  animation-name: customFadeOut;
}

/* Registration radio */
.radio-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25px;
}

.radio-text {
  padding: 10px;
  border: 1px solid #f4f9f8;
  border-radius: 8px;
  color: #f4f9f8;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  cursor: pointer;

  font-family: "Wix Madefor Display", sans-serif !important;
}

.radio-text-animal {
  padding: 5px;
  /* border-radius: 8px; */
  color: #122121;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  font-family: "Wix Madefor Display", sans-serif !important;
  transition: all 0.4s ease;
  border: 2px solid black;
}

.radio-text-animal:hover{
  background-color: #cae4d9;
  color: #000;
}

.selected-radio-text {
  padding: 10px;
  border: 1px solid #f4f9f8;
  border-radius: 8px;
  color: #122121;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  background-color: #f4f9f8;
  cursor: pointer;
  font-family: "Wix Madefor Display", sans-serif !important;
}

.selected-radio-text-animal {
  padding: 5px;
  border-radius: 2px;
  color: #f4f9f8;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  background-color: #122121;
  cursor: pointer;

  font-family: "Wix Madefor Display", sans-serif !important;
}

.label-radio-animal {
  color: #122121;
  margin-bottom: 10px;

  font-family: "Wix Madefor Display", sans-serif !important;
}

.label {
  color: #f4f9f8;
  font-size: 18px;
  margin-bottom: 10px;

  font-family: "Wix Madefor Display", sans-serif !important;
}

input[type="radio"] {
  display: none;
}

.label-upload {
  background-color: #122121;
  padding: 1rem;
  border-radius: 2px;
  cursor: pointer;
}

/* TABS */
.tabs {
  margin-top: 1rem;
  width: 100%;
  transition: all 0.4s ease-in;
  background-color: #122;
  padding: 1rem;
  border-radius: 10px;
  height: 100%;
}

.tabs > label.active {
  color: #fff;
  transition: all 0.4s ease;
}

.tabs > label.active > span {
  padding-bottom: 0.4rem;
  line-height: 2;
}

.tabs > label > span {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 10px;
}

.tabs > label > span::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #d15e41;
  transition: width 0.3s;
}

.tabs > label.active > span::before {
  width: 100%;
}


.tabs > label.active > img{
  display: none;
}

.tabs > label {
  cursor: pointer;
  padding: 2rem;
  margin-bottom: 1rem;
  color: white;
  border-radius: 2px 2px;
  line-height: 10px;
  position: relative;
}

.tabs-content {
  background-color: #f4f9f8;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 10px;
}

.tabs-content.not{
  display: none;
}

.tabs-content.active{
  display: block;
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% { opacity: 0; }
  25% { opacity: 0.25;}
  50% { opacity: 0.5;}
  75% { opacity: 0.75;}
  100% { opacity: 1; }
}

.content-bloc{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  column-gap: 2rem;
  align-items: flex-start;
}


.column-bloc {
  box-shadow:  1px 0 0 0 #d1d3d4, 1px 0 0 0 #d1d3d4;
  height: 100%;
}
/* .content-bloc > div:not(:last-child) {
  border-right: 2px solid #756f6f57;
} */



.column-bloc{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
}

.column-bloc > .span-div-bloc > span, .column-bloc > .span-div-bloc > p, .column-bloc > .span-div-bloc > .colors > div > p{
  font-size: 18px;
}

.column-bloc > .span-div-bloc > .label-colors > span{
  font-size: 18px;
}

.tab-icon-label, .tab-icon-label.active {
  display: none;
}

.tabs-content > div > p {
  color: #122121;
}

.tabs-content > div > p > a {
  color: #122121;
}

#content-animaux {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  background-color: #fff;
}

.stat-animal {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease-in;
}

.data-animal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 4px solid #2f4f4f;
  border-radius: 50%;
}

#content-secteurs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 2rem;
  padding: 1rem;
}

#content-secteurs-name{
  flex-wrap: wrap;
}
.data-animal-sector > p > a , .data-animal-sector > p > span {
  font-size: 16px !important;
  color: #2f4f2f;
  font-weight: 600 !important;
  border: 2px solid #2f4f2f;
  padding: 8px;
  border-radius: 50px;
}

.data-animal-sector > p > a:hover {
  font-size: 16px !important;
  color: #fff;
  font-weight: 600 !important;
  border: 2px solid #2f4f2f;
  padding: 8px;
  border-radius: 50px;
  background-color: #2f2f2f;
  transition: background-color 0.3s ease;
}

.data-animal-sector > p > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.stat-animal-sector-name{
  width: 100%;
}

.data-animal-sector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.data-animal > p {
  font-size: 18px !important;
  font-weight: bolder;
  margin: 0;
}

.label-animal > p,
.data-animal > p {
  color: #2f2f2f;
  margin: 7px;
}

.label-animal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-animal > p::first-letter{
  text-transform: uppercase;
}
.label-animal-more {
  font-size: 14px;
}

#content-informations {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 10rem;
  padding: 1rem;
}

.content-informations-canal > p {
  color: black;
}

.content-informations-canal > h3,
.content-informations-members > h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 2;
  margin-bottom: 1rem;
  color: #2f4f4f;
}
.content-informations-separator {
  border-left: solid 3px #2f4f4f;
  border-radius: 2px;
  width: 1px;
}

/* Sector page */

.city-page_content-title {
  width: 100%;
}

.city-page_cover{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
  z-index: 3;
}

.city-page_cover-show{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 3;
}


.section-button-general {
  width: 100%;
  margin-top: 1rem;
}

.city-page_content > .city-page_content-title > div {
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.city-page_content > .city-page_content-title > div > h3,
.city-page_content > .city-page_content-title > div > h2 {
  text-align: center;
}

.section-button-general {
  align-items: center;
}

.btns-simple-edit {
  margin: 0 !important;
}

footer {
  background-color: #ddd;
}

.footer-elt:hover, .header-elt:hover {
  color: #d15e41 !important;
}

.footer-elt{
  margin: 16px 0px;
}

/* File inputs */
.hidden-input {
  display: none;
}

.custom-file-button {
  color: #ddd;
  background-color: #122121;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.2s;
}

.custom-file-button:hover {
  background-color: #2f4f4f;
  transition: background 0.2s;
}

/* Documentation */
.documentation-container {
  display: flex;
  position: relative;
  background-color: #ddd;
}

.sidebar {
  position: fixed;
  top: 60;
  left: 0;
  height: 100vh;
  width: 15%;
  background-color: #ddd;
  padding: 20px;
  overflow-y: auto;
}

.documentation-content {
  margin-left: 15%;
  width: 85%;
  padding: 20px;
  overflow-y: auto;
  background-color: #f7f7f7;
  z-index: 4;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.sidebar > ul {
  list-style-type: none;
}

.sidebar > li {
  list-style-type: none;
  margin-bottom: 25px;
  font-size: 1.4rem;
}

.sidebar > li > a {
  color: black;
  list-style-type: none;
}

.section > h2 {
  font-size: 1.9rem;
  color: #122;
}

.section > p {
  color: #2f2f2f;
  font-size: 1.2rem;
  font-weight: 500;
}

.sidebar a.active {
  color: #fff;
  background-color: #2f4f4f;
  font-weight: bold;
  border-radius: 4px;
}

.sidebar a {
  padding: 11px;
}

table {
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

td {
  text-align: center;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  box-sizing: border-box;
}

.section-openable h2{
cursor: pointer;
padding-left: 1rem;
padding-top: 1rem;
}

.section-openable {
  padding-left: 1rem !important;
}
.section-content{
  padding: 0.6rem;
  padding-bottom: 1rem !important;
}

.content-openable{
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Documentation responsive */ 
.documentation-content-mobile{
  display: none;
}

/* Styles pour les écrans plus petits */
@media (max-width: 1200px) {
  .documentation-container {
    display: block;
  }

  /* Cachez la Sidebar et DocumentationContent */
  .sidebar, .documentation-content {
    display: none;
  }

  /* Affichez les sections déroulantes */
  .documentation-content-mobile {
    display: block;
    padding: 3rem;
  }
}

.section-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s, max-height 0.3s;
  color: rgb(17, 34, 34);
  padding: 1rem;
}

.section-content > p {
  color: #2f2f2f;
}

.section-open .section-content {
  max-height: 2500px;
  opacity: 1;
}


.section-openable h2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-openable h2::after {
  content: '›';
  transition: transform 0.3s ease;
  margin-bottom: 2px;
}

.section-open.section-openable h2::after {
  transform: rotate(90deg);
}

.create-animal-page {
  border-radius: 4px;
  padding: 0.2rem;
  background-color: #cae4d9;
}
.create-animal-page > .section-content {
  padding: 0rem;
}
.create-animal-page h2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
}

.create-animal-page > h2{
  font-size: 1rem !important;
  padding: 0rem;
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
}

.create-animal-page h2::after {
  content: '›';
  font-size: 1.4rem;
  transition: transform 0.3s ease;
}

.scroll {
  margin: 4px, 4px;
  padding: 4px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.scroll > p {
  color: #2f2f2f;
}


/* Paiements */ 

.products {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: center;
}

.product-card{
  width: 350px;
  overflow: hidden;
  transition: background-color 0.3s;

}

.product-label{

  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;
  transition: background-color 0.3s;

}

.product-label.disabled {
  opacity: 0.5; /* Rend le produit plus transparent */
  pointer-events: none; /* Désactive les événements de la souris sur l'élément */
  color: gray; /* Change la couleur du texte */
}

.product-label.disabled .product-title {
  text-decoration: line-through; /* Peut ajouter une ligne à travers le titre pour indiquer qu'il est désactivé */
}

.product-label:hover {
  background-color: #cae4d9;
}

.product-input:checked ~ * {
  background-color: #cae4d9 !important;
}


.product-input {
  display: none;
}

.product-label {
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
  height: 100%;
}

.product-image {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}

.product-title, .product-description, .product-price {
  margin: 10px 0;
  color: #122 !important;
  text-align: center;
}

.product-description {
  font-weight: 500 !important;
}

.select-text {
  display: block;
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
  color: #2f4f2f;
}


.subscribe-bloc-2{
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
}


.billing-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: "Wix Madefor Display", sans-serif;
  font-weight: 500;
}

.billing-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.billing-buttons button.active {
  background-color: #2f4f4f;
  color: white;
  border-color: #cae4d9;
}

.billing-buttons button:hover:not(.active) {
  background-color: #e0e0e0;
}

.product-saving {
  color: #d15e41 !important;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}


/* Facturation */ 
.facturation-elt{
  padding-left: 1rem;
}

.facturation-elt-title{
  color: #2f4f2f;
  padding-left: 2rem;
}

.facturation-elt-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 2px solid #2f4f4f;
  padding-left: 2rem;
}

/* No result found */
.no-result-found{
  background-color: #fff;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin:2rem;
  padding:2rem;
}

.no-result-found-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
}

.no-result-found-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}
.no-result-found-text> p{
  color: #000;
  font-weight: bold;
}
.no-result-found-logos{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logos-result{
  max-width: 35px;
}

.red-terms, .red-terms > a{
  color: #c40030;
  /* text-decoration: underline; */
  font-weight: 600;
}

.valid-terms, .valid-terms > a {
  color: #00c472;
  /* text-decoration: underline; */
  font-weight: 600;
}

.classic-terms, .classic-terms > a{
  color: #000;
  /* text-decoration: underline; */
  font-weight: 600;
}



#content-chatperdu{
  display: flex;
  flex-direction: column;
}

.content-chatperdu_banner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

/* .content-chatperdu_ */

.cards-container-chat {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.card-chatperdu {
  width: 200px;
  margin-right: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  background-color: white;
}

.card-image-chatperdu {
  width: 100%;
  height: auto;
}

.card-info-chatperdu {
  padding: 10px;
}

.card-info-chatperdu > p {
  color:#000;
}

.label-chatperdu {
  font-weight: bold;
  color: #000;
}



/* Ajouter dans votre fichier CSS */
.axis path,
.axis line {
    stroke: black !important;
}

.axis text {
    fill: black !important;
}


.graph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  row-gap: 3rem;
}

.graph-self div, .graph-self div span {
  flex: 1;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-radius: 5px;
  background-color: #fff;
}



.signalements-graph > div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: unset !important;
}

.signalements-count {
  font-size: 2em;
  font-weight: bold;
  color: #69b3a2;
  box-shadow: unset !important;
  border: none !important;
  font-family: "Wix Madefor Display", sans-serif;
}

.signalements-graph  * {
  box-shadow: unset !important;
}

.signalements-label {
  font-size: 1em;
  margin-top: 10px;
  color: #000;
  box-shadow: none !important;
  border: none !important;
  text-align: center;
  font-family: "Wix Madefor Display", sans-serif;
}


.a4-size {
  max-width: 210mm;
  margin: auto;
}

@media print {
  .graph-component {
      width: 100%;
  }
}

.graph {
  width: 100%;
  overflow-x: auto;
}

.graph-scroll{
  display: none;
}


/* PREVIEWS UPDATE DATE WITH AI */ 
.preview-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  min-width: 800px;
}

.preview-section {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.preview-section h3 {
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.preview-section > p {
  color: #000;
}

.modalqs-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalqs-content {
  background-color: #2f4f4f;
  padding: 20px;
  border-radius: 5px;
  padding-left: 2%;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.floating-button-qs {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.quicksearch-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.quicksearch-cards > .card-animal {
  max-width: 250px;
  min-width: 250px;
  min-height: 360px;
  max-height: 360px;
  height: 100%;
  width: 100%;
}

.quicksearch-cards > .card-animal > .card-animal__image-container {
  height: 100%;
}


.city-page_content{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1rem;
  position: relative;
  border-radius: 10px 10px 0px 0px;
}

.city-page_content::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 10px 10px 0px 0px;
}

.city-page_content-title, .city-page_content > .section-button-general{
  z-index: 1;
}

.city-page_main > .section-informations > .tabs-content {
  width: 100%;
  margin-top: 0;
  border-radius: 0px 0px 10px 10px;
}

.city-page_main > .section-informations {
  margin-top: 0;
}

.city-page_main-editmode{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #ddd;
}
.canal-page_content-title{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1rem;
  position: relative;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
}

.canal-page_content-title::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 10px 10px 10px 10px;
}

.canal-page_content-title-img, .canal-page_content-title-shareCanal, .canal-page_content-title > h1, .canal-page_content-title > span, .canal-statistics-header{
  position: relative;
  z-index: 1;
}

.canal-page_content-title-shareCanal-button:hover{
  background-color: #fff !important;
}
/* Carousel */ 
.yarl__carousel {
  background-color: #122;
}

.react-tooltip{
  z-index: 2;
}


/* CitySelect */ 
/* .citySelectContainer{
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;
  max-width: 250px;
  align-items: flex-start;
  justify-content: center;
}

.citySelectPostalCode, .citySelectCity {
  border-radius: 3px;
  padding: 11px;
  font-weight: 600;
  width: 350px !important;


}
*/

.citySelectContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px; /* Espacement entre les éléments */
  max-width: 500px; /* Ajustez selon le besoin */
  margin-top: 1rem;
}

.citySelectPostalCode, .citySelectCity {
  width: 100%; /* Utiliser la totalité de la largeur disponible du conteneur */
  padding: 10px; /* Ajustez pour le confort de lecture */
  box-sizing: border-box; /* S'assure que le padding ne change pas la largeur totale */
  font-weight: 600;
}


.citiesListByCpInput{
  text-align: center;
}

.canalsListCities{
  display: flex;
  flex-direction: column;
}

.canalsListCities > .canalItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.canalsListCities > .canalItem > span{
  font-weight: 600;
}

.canalsListContainer {
  padding-top: 1rem;
}

/* Responsive design pour les écrans plus petits */
@media (max-width: 600px) {
  .citySelectContainer {
    width: 90%; /* Augmenter la largeur du conteneur sur les petits écrans */
  }

  .citySelectPostalCode, .citySelectCity {
    font-size: 14px; /* Ajuster la taille de texte si nécessaire */
  }
}


.joinedButton {
  background-color: #2f4f4f98; /* Couleur plus claire */
  color: #fff; /* Texte plus sombre pour contraster */
  cursor: not-allowed; /* Change le curseur pour indiquer l'incapacité de cliquer */
}

.joinButton {
  background-color: #2f4f4f; /* Une couleur vive pour attirer l'attention */
  color: white; /* Texte en blanc pour le contraste */
  cursor: pointer; /* Change le curseur pour indiquer la possibilité de cliquer */
  border: none; /* Aucune bordure pour un look plus moderne */
}


.canalItem {
  border-bottom: 1px solid #ccc; /* Couleur grise claire pour la bordure */
  padding: 10px 0; /* Ajoute un peu d'espace autour du contenu de chaque item */
  margin: 10px 0; /* Ajoute un peu d'espace entre les items */
}

.canalsListCities {
  max-height: 300px; /* Hauteur maximale avant que le défilement ne soit activé */
  overflow-y: auto; /* Active le défilement vertical si le contenu dépasse la hauteur maximale */
  margin-top: 10px; /* Ajustez selon le besoin pour l'espacement autour du conteneur */
  border-top: 1px solid #ccc; /* Optionnel: ajoute une séparation visuelle entre l'entrée et les résultats */
}


/* Progress Bar */ 


.progress-container {
  width: 100%;
  background-color: #7cc0ac;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 20px; 
}

.progress-bar {
  height: 100%; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  border-radius: 8px;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.progress-max {
  color: #000; 
  font-size: 1rem;
}


/* Media queries pour ajuster la taille du texte de la progression et du maximum */
@media (max-width: 600px) {
  .progress-bar {
    font-size: 0.65rem;
  }
  .progress-max {
    font-size: 0.65rem;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .progress-bar {
    font-size: 0.75rem;
  }
  .progress-max {
    font-size: 0.75rem;
  }
}

@media (min-width: 1025px) {
  .progress-bar {
    font-size: 0.85rem;
  }
  .progress-max {
    font-size: 0.85rem;
  }
}

/* Email is not valid */ 
.container {
  position: fixed; /* Fixe le conteneur au bas de la page */
  bottom: 0; /* Positionne le conteneur tout en bas */
  width: 100%; /* Assure que le conteneur occupe toute la largeur */
  background-color: #d4edda; /* Fond vert clair */
  padding: 20px; /* Ajoute un peu d'espace autour du contenu interne */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Ajoute une légère ombre en haut du conteneur */
  text-align: center; /* Centre le texte et les boutons à l'intérieur */
}


.container > p {
  color: #000;
}


/* Map integration */ 
.map-container {
  width: 80vw;
  height: 60vh;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 1rem;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


/* .leaflet-popup > * {
  background-color: red !important;
} */
.leaflet-popup-content-wrapper, .leaflet-popup-tip, .leaflet-popup-content{
  background-color: #2f4f4f !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaflet-popup-content > img{
  max-width: 50px;
}

.leaflet-popup-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120px;
}
.leaflet-popup-content > img{
  max-width: 100px;
  border-radius: 4px;
}

.leaflet-popup-close-button{
  color: white !important;
}

.leaflet-popup-close-button:hover{
  color: #d15e41 !important;
}


/* #modal-association{
  z-index: 9999 !important;
} */

.react-tooltip{
  z-index: 99999 !important;
  /* background-color: #2f4f4f !important; */
}


/* Signal modal */ 
.modal-background-signal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modal-container-signal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

/* Curseur Devis */ 
#slider {
  all: unset !important;
  appearance: none !important;
  width: 100% !important;
  height: 17px !important;
  background: #2f4f4f !important;
  outline: none !important;
  border-radius: 50px !important;
  margin-bottom: 1rem !important;
}

#slider::-webkit-slider-thumb {
  appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background: #d15e41 !important;
  cursor: pointer;
}

#slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: pointer;
}

/* Marqueurs */
.datalist-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #2F4F4F;
  margin-top: 10px;
  width: 100%;
}

.range-labels {
  display: flex !important;
  justify-content: space-between !important;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 1rem;
}

